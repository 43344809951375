/* footer */

.main-footer {
 /* position: relative; */
 background-color: $footer-bg;
 z-index: 1;
 position: relative;
 /* left: 0rem;
    top: 0rem;
    right: 0rem;
    bottom: 0rem; */
 box-shadow: $clr-top-box-shadow;
}

.main-footer.style-two {
 border-top: 0.0625rem solid $clr-text-two;
}

.main-footer .footer-widget {
 position: relative;
 margin-bottom: 1.875rem;
 margin-top: 1.25rem;
 // margin-bottom: 0;
 // margin-top: 0;
}

.main-footer .widgets-section {
 position: relative;
 padding: 1.25rem 0rem 0rem;
}

.main-footer .footer-widget h5 {
 position: relative;
 font-weight: $font-medium;
 color: $footer-text;
 line-height: 1.75rem;
 margin-top: 1.5625rem;
 margin-bottom: 1.5625rem;
 text-transform: capitalize;
 font-size: 0.875rem;
 font-family: $font-family-opensans;
}

.main-footer .logo-widget {
 position: relative;
}

.main-footer .logo-widget .logo {
 position: relative;
 margin-bottom: 0rem;
}

.main-footer .logo-widget .call {
 position: relative;
 color: $clr-text-nine;
 font-size: 0.875rem;
 font-weight: $font-regular;
 line-height: 1.8em;
 text-transform: uppercase;
}

.main-footer .logo-widget .call .phone {
 position: relative;
 color: $clr-black;
 font-size: 1.5rem;
 display: block;
 margin-top: 0.9375rem;
 margin-bottom: 0.4375rem;
 transition: all 500ms ease;
 -moz-transition: all 500ms ease;
 -webkit-transition: all 500ms ease;
 -ms-transition: all 500ms ease;
 -o-transition: all 500ms ease;
}

.main-footer .logo-widget .call .email {
 position: relative;
 color: $clr-black;
 font-size: 1rem;
 display: block;
 margin-top: 0rem;
 text-transform: capitalize;
 transition: all 500ms ease;
 -moz-transition: all 500ms ease;
 -webkit-transition: all 500ms ease;
 -ms-transition: all 500ms ease;
 -o-transition: all 500ms ease;
}

.main-footer .logo-widget .call .phone:hover,
.main-footer .logo-widget .call .email:hover {
 color: $clr-hyperlink;
}

/* List Link */

.list-link {
 position: relative;
 list-style: none;
}

.list-link li {
 position: relative;
 margin-bottom: 0.5rem;
}

.list-link li a {
 position: relative;
 color: $footer-text;
 font-size: 0.875rem;
 font-weight: $font-regular;
 font-family: $font-family-opensans;
 line-height: 0rem;
 text-decoration: none;
 -webkit-transition: all 300ms ease;
 -ms-transition: all 300ms ease;
 -o-transition: all 300ms ease;
 -moz-transition: all 300ms ease;
 transition: all 300ms ease;
}

ul.list-link {
 padding-left: 0;
 padding-right: 15px;
}

.list-link li a:hover {
 color: #0976bd;
}

.main-footer .newsletter-widget .text {
 position: relative;
 color: $footer-text;
 font-size: 0.8125rem;
 line-height: 1.125rem;
 margin-bottom: 0.9375rem;
 margin-top: 1.25rem;
 font-family: $font-family-ptsans;
 font-weight: $font-regular;
 padding-right: 15px;
}

.mobile-footer {
 display: none;
}
p.copyright {
 text-align: center;
 padding: 0.625rem 15px;
 color: $footer-text;
 font-size: 0.875rem;
 font-weight: $font-medium;
 font-family: $font-family-opensans;
 line-height: 1.75rem;
 border-top: 0.0625rem solid $footer-text;
}
a.icofont-ui-call {
 border: 0.0625rem solid $footer-text;
 border-radius: 3.125rem;
 padding: 0.625rem;
 margin-right: 0.9375rem;
}
a.icofont-location-pin {
 border: 0.0625rem solid $footer-text;
 border-radius: 3.125rem;
 padding: 0.625rem;
 margin-right: 0.9375rem;
}

a.icofont-envelope {
 border: 0.0625rem solid $footer-text;
 border-radius: 3.125rem;
 padding: 0.625rem;
 margin-right: 0.9375rem;
}
.foot-logo {
 position: relative;
 width: 100%;
}
.foot-logo img {
 height: 50px;
 background: #fff;
}
p.broker-ftext {
 font-size: 13px;
 line-height: 1.8;
}
